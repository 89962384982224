import Vue from 'vue'
import Router from 'vue-router'
import Layout from '@/components/Layout/index.vue'

Vue.use(Router)

// 路由设置√
// 登录路由设置√
// 登录拦截去掉√
// 定时刷新去掉√
// 去掉菜单权限√
// 右上角登录去掉√
const router = new Router({
  routes: [
    // {
    //   path: '/login',
    //   name: 'login',
    //   hidden: true,
    //   component: () => import('../Common/views/login/index.vue'),
    //   meta: {
    //     menuShow: false,
    //     menuName: '登录'
    //   }
    // },
    {
      path: '/',
      hidden: true,
      redirect: 'statistics',
      meta: {
        menuShow: false
      }
    },
    {
      path: '/home',
      hidden: true,
      redirect: 'statistics',
      meta: {
        menuShow: false
      }
    },
    {
      path: 'statistics',
      component: Layout,
      redirect: '/statistics',
      children: [{
        path: '/statistics',
        name: 'statistics',
        component: () => import('./views/chainData/index.vue'),
        meta: {
          menuShow: true,
          breadcrumbShow: true,
          breadcrumb: true,
          title: '链数据统计',
          icon: 'el-icon-pie-chart'
        }
      },
      {
        path: '/chainData/listblockMore',
        name: 'listblockMore',
        component: () => import('./views/block/index.vue'),
        hidden: true,
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '区块列表',
          parent: 'statistics'
        }
      },
      {
        path: '/chainData/listblockMore/blockDetails',
        name: 'chainBlockDetails',
        component: () => import('./views/block/details.vue'),
        hidden: true,
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '区块信息',
          parent: 'listblockMore'
        }
      },
      {
        path: '/chainData/listLedgerMore',
        name: 'listLedgerMore',
        component: () => import('./views/ledger/index.vue'),
        hidden: true,
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '账本列表',
          parent: 'statistics'
        }
      },
      {
        path: '/chainData/listLedgerMore/ledgerDetails',
        name: 'chainLedgerDetails',
        component: () => import('./views/ledger/details.vue'),
        hidden: true,
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '账本信息',
          parent: 'listLedgerMore'
        }
      },
      {
        path: '/chainData/details',
        name: 'chainDataDetails',
        component: () => import('./views/chainData/details.vue'),
        hidden: true,
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '链数据详情',
          parent: 'statistics'
        }
      }
      ]
    },
    // {
    //   path: 'dictionary',
    //   component: Layout,
    //   redirect: '/dictionary',
    //   children: [{
    //     path: '/dictionary',
    //     name: 'dictionary',
    //     component: () => import('./views/dictionary/index.vue'),
    //     meta: {
    //       menuShow: true,
    //       breadcrumbShow: true,
    //       breadcrumb: true,
    //       title: '数据字典'
    //       // icon: 'ym-icon-shaixuan1'
    //     }
    //   }]
    // },
    {
      path: 'block',
      component: Layout,
      redirect: '/block',
      children: [{
        path: '/block',
        name: 'block',
        component: () => import('./views/block/index.vue'),
        meta: {
          menuShow: true,
          breadcrumbShow: true,
          breadcrumb: true,
          title: '区块列表',
          icon: 'el-icon-files'
        }
      },
      {
        path: '/block/details',
        name: 'blockDetails',
        component: () => import('./views/block/details.vue'),
        hidden: true,
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '区块信息',
          parent: 'block'
        }
      }
      ]
    },
    {
      path: 'ledger',
      component: Layout,
      redirect: '/ledger',
      children: [{
        path: '/ledger',
        name: 'ledger',
        component: () => import('./views/ledger/index.vue'),
        meta: {
          menuShow: true,
          breadcrumbShow: true,
          breadcrumb: true,
          title: '账本列表',
          icon: 'el-icon-notebook-2'
        }
      },
      {
        path: '/ledger/details',
        name: 'ledgerDetails',
        component: () => import('./views/ledger/details.vue'),
        hidden: true,
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '账本信息',
          parent: 'ledger'
        }
      }
      ]
    },
    {
      path: 'account',
      component: Layout,
      redirect: '/account',
      children: [{
        path: '/account',
        name: 'account',
        component: () => import('./views/account/index.vue'),
        meta: {
          menuShow: true,
          breadcrumbShow: true,
          breadcrumb: true,
          title: '记账列表',
          icon: 'el-icon-edit-outline'
        }
      },
      {
        path: '/account/details',
        name: 'accountDetails',
        component: () => import('./views/account/details.vue'),
        hidden: true,
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '账户信息',
          parent: 'account'
        }
      }
      ]
    },
    {
      path: 'workStatus',
      component: Layout,
      redirect: '/workStatus',
      children: [{
        path: '/workStatus',
        name: 'workStatus',
        component: () => import('./views/workStatus/index.vue'),
        meta: {
          menuShow: true,
          breadcrumbShow: true,
          breadcrumb: true,
          title: '状态列表',
          icon: 'ym-icon-shaixuan1'
        }
      },
      {
        path: '/workStatus/details',
        name: 'workStatusDetails',
        component: () => import('./views/workStatus/details.vue'),
        hidden: true,
        meta: {
          menuShow: false,
          breadcrumbShow: true,
          title: '状态信息',
          parent: 'workStatus'
        }
      }
      ]
    }
    // {
    //   path: 'collection',
    //   component: Layout,
    //   redirect: '/collection',
    //   children: [{
    //     path: '/collection',
    //     name: 'collection',
    //     component: () => import('./views/collection/index.vue'),
    //     meta: {
    //       menuShow: true,
    //       breadcrumbShow: true,
    //       breadcrumb: true,
    //       title: '分片设置'
    //       // icon: 'ym-icon-shaixuan1'
    //     }
    //   }]
    // }
  ]
}
)

// 解决报错
const originalPush = Router.prototype.push
const originalReplace = Router.prototype.replace
// push
Router.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}
// replace
Router.prototype.replace = function push (location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

export default router
